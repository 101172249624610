<template>
    <div style="position:relative;">
        <div style="height: 20px;
                        background: rgba(216,216,216,0);
                        border-radius: 4px 4px 4px 4px;
                        opacity: 1;
                        font-size: 12px;

                        font-weight: 400;
                        color: #3D3D3D;
                        padding-left: 8px;
                        padding-right: 8px; 
                        line-height: 16px;
                        margin-left: 8px;
                        margin-right: 8px;
                        border: 1px solid rgba(61,61,61,0.53);">
            {{pay.text}}
        </div>
        <img @click="onCancelSelect" style="position:absolute;
                            width: 12px;
                            height: 12px;
                            right:3px;
                            top:-3px;
                            background: #F1F1F1;
                            border-radius: 6px 6px 6px 6px;
                            opacity: 1;
                            border: 1px solid rgba(61,61,61,0.53);" src="../../assets/img/close.png" />
    </div>
</template>
<script>
export default {
    name: "ItemPayMethod",
    props: {
        pay: {},
        cancel: {
            type: Function
        }
    },
    methods: {
        onCancelSelect() {
            if (this.cancel) {
                this.cancel(this.pay)
            }
        }
    }
}
</script>