<template>
  <div class="root_container">
    <div class="header-box">
      <div class="advertise_header" id="advertise_header">

        <!-- center -->
        <BuySellTab :onTabChange="onSellBuytabChange" />

        <!-- right  -->
        <div class="header-right">
          <img class="header-user png_right" @click="toCenter" style="opacity: 0.54;margin-left: 8px;"
            src="../assets/img/user.png" />

          <div class="menu_content" @click="toAdUserList">
            <img class="header-img" src="../assets/img/create_menu.png" />
          </div>
        </div>

      </div>
    </div>
    <div class="create_content ">

      <TabContent :type="'BUY'" v-show="sellBuyType == 1" />

      <TabContent :type="'SELL'" v-show="sellBuyType != 1" />

    </div>
  </div>

</template>

<script>
import BuySellTab from '../components/BuySellTab.vue';
import TabContent from './create-advertise/TabContent.vue';
export default {
  name: "AdvertiseCreate",
  data() {
    return {
      sellBuyType: 1,
    }
  },
  components: { BuySellTab, TabContent },
  methods: {
    onSellBuytabChange(index) {
      this.sellBuyType = index;
      console.dir("index :" + index);
    },
    toCenter() {
      this.$router.push({ path: '/merchant-center' })
    },
    toAdUserList() {
      this.$router.push({ path: "/advertise-user-list" });
    },
  }
};
</script>
<style scoped>
.root_container {
  height: 100%;
  display: flex;
  flex-direction: column
}

.header-box {
  padding-top: 30px;
  background-color: #F2F2F2;
}

/* .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 42px 32px 30px;
  border-radius: 50px 50px 0 0;
  background-color: #FFFFFF;
} */
.header-right {
  display: flex;
  align-items: center;
}

.header-user {
  width: 42px;
  height: 42px;
}


.header-img {
  width: 32px;
  height: 32px;
}

.png_right {
  margin-right: 30px;
}

.advertise_header {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 0 12px;
  padding: 42px 32px 30px;
  border-radius: 50px 50px 0 0;
  background-color: #FFFFFF;
}

.menu_content {
  display: flex;
  margin: auto 0;
}

.create_content {
  /* position: absolute;
  top: 118px;
  left: 0;
  right: 0;
  bottom: 0; */
  flex: 1;
}
</style>
