<template>

  <div class="root_container">
    <div class="tab-box">
      <div class="container-flex-h-order tab-bg">
        <div class="tab-item" v-for="(item, index) in getCoin" :key="index" @click="coin.select = index">
          <p :class="coin.select === index ? 'tab-item-select ' : ''">{{ item.EnName }}</p>
        </div>
      </div>
    </div>

    <div style="overflow: scroll;height: 100%;">
      <CreateInfo :actionType="type" :coin="getCoin[coin.select]" />
    </div>
  </div>

</template>
<script>
import CreateInfo from './CreateInfo.vue';
import { mapGetters } from "vuex";

export default {
  name: "CreateSellBuyContent",
  props: {
    type: {
      type: String,
      default: "BUY",
    },
  },
  computed: {
    ...mapGetters(["protocol", "coinList"]),
    getCoin() {
      const protocol = this.coinList.find(item => item.Protocol == this.protocol) || {}
      const arr = this.type === "BUY" ? protocol.BuyCoin : protocol.SellCoin
      return arr || []
    }
  },
  data() {
    return {
      coin: {
        select: 0, // index
      },
    };
  },
  components: { CreateInfo }
}
</script>
<style scoped>
.root_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  flex-basis: 0;
}

.tab-item {
  font-size: 29px;
  font-weight: 600;
  color: #666666;
  margin-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.tab-item-select {
  border-bottom: 4px solid #3665B7;
  color: #3665B7;
  font-weight: 400;
}

.tab-bg {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab-bg::-webkit-scrollbar {
  display: none;
}

.tab-box {
  align-items: center;
  padding: 0 30px;
  background-color: #F2F2F2;
}
</style>