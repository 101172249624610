<template>
  <div>
    <!-- <div class="header-box">
      <div class="header"> -->
    <div class="root_padding">
      <!-- 1 -->
      <div class="container-flex-h-center top_item_margin">
        <p class="title">
          {{ $t(actionType === "BUY" ? 'create-buy' : 'create-sell') }}
        </p>
        <p class="title_tip" v-if="actionType === 'SELL'">{{ $t("aviable") }} {{ balanceText }}</p>
      </div>

      <div class="input-bg input_content_conmon">
        <van-field class="input" rows="1" autosize v-model="formData.amount" :placeholder="
          $t(actionType === 'BUY' ? 'create_buy_number' : 'create-sell-hint')
        ">
          <template #button>
            <div class="container-flex-h info-item input_left">
              <div class="divider_input" />
              <p class="unit_text">{{ coin.EnName }}</p>
            </div>
          </template>
        </van-field>
      </div>

      <!-- 2 -->
      <p class="title item_margin">
        {{ $t(actionType === 'BUY' ? 'create_buy_price' : 'create_sell_price') }}
      </p>
      <div>
        <div class="input-bg input_content_conmon">
          <van-field class="input" rows="1" autosize v-model="formData.price"
            :placeholder="$t(actionType === 'BUY' ? 'creat_buy_price_hint' : 'creat_sell_price_hint')">
            <template #button>
              <div class="container-flex-h info-item input_left">
                <div class="divider_input" />
                <my-selected class="unit_text" v-model="formData.fiat" :actions="currencyList"></my-selected>
              </div>
            </template>
          </van-field>
        </div>
      </div>

      <!-- 3 -->
      <p class="title item_margin">
        {{ $t(actionType === 'BUY' ? 'createbuy_limit' : 'create_sell_limit') }}({{ fiatText }})</p>
      <div class="input-bg input_content_conmon">
        <van-field class="input" rows="1" autosize v-model="formData.minAmount" :placeholder="$t('order-min-amount')" />
        <div class="divider_h" />
        <div class="divider_h" />
        <van-field class="input" input-align="right" rows="1" autosize v-model="formData.maxAmount"
          :placeholder="$t('order-max-amount')" />
      </div>

      <!-- 4 -->
      <p class="title item_margin">{{ $t("pay-method") }}</p>
      <div>
        <my-selected class="unit_text select_bg" v-model="formData.payment" padding multiple
          :placeholder="$t('creat_select')" :actions="paymentList">
        </my-selected>
      </div>

      <!-- 5 -->
      <p class="title item_margin">{{ $t("message-crate") }}</p>
      <div class="input-bg-mul input_content_conmon">
        <van-field class="input_mul" v-model="formData.message" rows="2" autosize type="textarea" maxlength="100"
          :placeholder="$t('inpout-message')" show-word-limit />
      </div>

      <div class="container-flex-h-center">
        <van-button v-if="info.approve || actionType === 'BUY'" type="primary" color="#3665B7" class="botton_create"
          :loading="
          addLoading" @click="sendAd">{{ $t("create-gg") }}
        </van-button>

        <van-button v-else type="primary" color="#0875C4" class="botton_approve" :loading="approveLoading"
          @click="approve">{{ $t('approve') }}
        </van-button>
      </div>
      <Tips class="tip_content" type="ad" />
    </div>
    <!-- </div>
    </div> -->
  </div>
</template>
<script>
import ItemPathMethod from "./ItemPathMethod.vue";
import Tips from "../../components/Tips.vue";
import MySelected from "../../components/my-selected";
import { CurrencyGet, PaymentGet, ReleaseAdvertise } from "../../api/httpApi";
import { CreateAd, CreateInfo } from "../../api/otc";
import { getAccounts } from "../../utils/wallet";
import { mapGetters } from "vuex"
import BigNumber from "bignumber.js";
import { getOTCAddress } from "../../utils/addressHelpers";
import { USDTApprove } from "../../api/token";
import { getSessionId, isApp } from "../../api/app";

export default {
  name: "CreateInfo",
  components: { ItemPathMethod, Tips, MySelected },
  props: {
    actionType: {
      type: String,
      default: "BUY",
    },
    coin: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    ...mapGetters(["protocol", "coinList"]),
    balanceText() {
      return this.info.balance.div(new BigNumber(10).pow(this.coin.Decimals)).decimalPlaces(4).toString()
    },
    fiatText() {
      const item = this.currencyList.find(item => item.value == this.formData.fiat) || {}
      return item.name
    }
  },
  data() {
    return {
      fb: {
        show: false,
      },
      pay: {
        show: false,
      },
      account: "",
      infoLoading: false,
      info: {
        approve: false,
        balance: new BigNumber(0),
        traderMaxAdCount: 0,
        list: [],
      },
      approveLoading: false,
      addLoading: false,
      formData: {
        amount: "",
        minAmount: "",
        maxAmount: "",
        price: "",
        fiat: "",
        payment: [],
        message: "",
      },
      paymentList: [],
      currencyList: [],
    };
  },
  mounted() {
    this.getPayment()
    this.getCurrency()
    this.getAuthorizeInfo().then()
  },
  methods: {
    async getAuthorizeInfo() {
      const accounts = await getAccounts()
      this.account = accounts[0]
      await this.getInfo()
    },
    async getInfo() {
      if (this.infoLoading) {
        return false
      }
      this.infoLoading = true
      this.info = await CreateInfo(this.coin.Ext || "", this.account)
      this.infoLoading = false
    },
    getPayment() {
      const data = {}
      PaymentGet(data)
        .then(res => {
          if (res.status > 1) {
            return false
          }
          const list = res.data
          this.paymentList = list.map(item => {
            return {
              value: item.Id,
              name: item.Name,
              logo: item.logo,
            }
          })
        })
    },
    getCurrency() {
      const data = {}
      CurrencyGet(data)
        .then(res => {
          if (res.status > 1) {
            return false
          }
          const list = res.data
          if (list.length === 0) {
            return false
          }
          this.currencyList = list.map(item => {
            return {
              value: item.Id,
              name: item.EnName,
              logo: item.logo,
            }
          })
          this.formData.fiat = this.currencyList[0].value
        })
    },
    async approve() {
      console.dir("授权");
      if (this.approveLoading) {
        return
      }
      const otcAddress = getOTCAddress()
      this.approveLoading = true
      const res = await USDTApprove(otcAddress)
      this.approveLoading = false
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: '授权取消',
          duration: 2000,
          position: 'top-left',
        });
        return
      }
      this.$notify({
        type: 'success',
        message: '授权成功',
        duration: 2000,
        position: 'top-left',
      });
      this.info.approve = true
    },
    async sendAd() {
      if (this.addLoading) {
        return false
      }
      if (this.info.list.length >= this.info.traderMaxAdCount) {
        this.$notify({
          type: 'warning',
          message: `当前未处理的广告超过${this.info.traderMaxAdCount}个`,
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      const accounts = await getAccounts()
      if (accounts.length === 0) {
        this.$notify({
          type: 'warning',
          message: '请连接钱包',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      const account = accounts[0]
      const adType = this.actionType === "BUY" ? 1 : 0
      if (!this.formData.amount) {
        this.$notify({
          type: 'warning',
          message: '请输入数量',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      const amountBig = new BigNumber(this.formData.amount).times(new BigNumber(10).pow(this.coin.Decimals))
      if (adType === 0) {
        if (this.info.balance.comparedTo(amountBig) < 0) {
          this.$notify({
            type: 'warning',
            message: '余额不足',
            duration: 2000,
            position: 'top-left',
          });
          return false
        }
      }
      if (!this.formData.price) {
        this.$notify({
          type: 'warning',
          message: '请输入价格',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      if (this.formData.payment.length === 0) {
        this.$notify({
          type: 'warning',
          message: '请选择支付方式',
          duration: 2000,
          position: 'top-left',
        });
        return false
      }
      const price = new BigNumber(this.formData.price).times(new BigNumber(1e18)).toString(10)
      const minAmount = new BigNumber(this.formData.minAmount).times(new BigNumber(1e18)).toString(10)
      console.log(minAmount)
      const maxAmount = new BigNumber(this.formData.maxAmount).times(new BigNumber(1e18)).toString(10)
      const sessionId = await getSessionId()
      const data = {
        token: this.coin.Ext,
        adType: adType,
        amount: amountBig.toString(10),
        price: price,
        fiat: this.formData.fiat,
        singleMin: minAmount,
        singleMax: maxAmount,
        pay: this.formData.payment,
      }
      this.addLoading = true
      const res = await CreateAd(data)
      this.addLoading = false
      if (res === false) {
        this.$notify({
          type: 'warning',
          message: '授权取消',
          duration: 2000,
          position: 'top-left',
        });
        return
      }
      this.$notify({
        type: 'success',
        message: '发布广告成功',
        duration: 2000,
        position: 'top-left',
      });

      const hash = res.transactionHash

      const httpData = {
        CoinId: this.coin.Id,
        Address: account,
        OrderHash: hash,
        Num: this.formData.amount,
        Mold: adType,
        Price: this.formData.price,
        CurrencyId: this.formData.fiat,
        PayId: this.formData.payment,
        Min: this.formData.minAmount,
        Max: this.formData.maxAmount,
        Protocol: this.protocol,
        Remark: this.formData.message,
        SessionId: sessionId,
      }

      ReleaseAdvertise(httpData).then(() => {
        this.toAdUserList()
      })
    },
    toAdUserList() {
      this.$router.push({ path: "/advertise-user-list" });
    },
  },
};
</script>
<style scoped>
.tip_content {
  margin-top: 50px;
  margin-bottom: 50px;
}

.divider_h {
  height: 2px;
  width: 32px;
  margin-left: 2px;
  background-color: #BDBDBD;
}

.top_item_margin {
  margin-top: 39px;
}

.item_margin {
  margin-top: 20px;
}

.root_padding {
  padding: 0 30px;
}

.unit_text {
  font-size: 24px;
  font-weight: 500;
  color: #666666;
}

.botton_create {
  height: 80px;
  flex: 1;
  border-radius: 10px;
  border: 4px 1px #3665B7;
  margin-top: 70px;
  margin-left: 55px;
  margin-right: 55px;
  font-size: 32px;
  font-weight: 400;
  color: #FFFFFF;
}

.botton_approve {
  height: 80px;
  flex: 1;
  border-radius: 10px;
  border: 4px 1px #0875C4;
  margin-top: 70px;
  margin-left: 55px;
  margin-right: 55px;
  font-size: 32px;
  font-weight: 400;
  color: #FFFFFF;
}


.header-box {
  padding-top: 30px;
  background-color: #F2F2F2;
}

.divider_input {
  width: 2px;
  height: 36px;
  background: #EBEBEB;
  margin-left: 20px;
  margin-right: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 42px 32px 30px;
  border-radius: 50px 50px 0 0;
  background-color: #FFFFFF;
}

.select_bg {
  height: 88px;
  background: #F5F5F5;
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 10px;
}

.title {
  /* font-size: 24px;
  font-weight: 400;
  color: rgba(61, 61, 61, 0.53); */
  font-size: 28px;
  font-weight: 600;
  color: #999999;
}

.title_tip {
  font-size: 26px;
  font-weight: 500;
  color: #BDBDBD;
}

.input_left {
  align-items: center;
}

.text-strong {
  font-size: 24px;
  font-weight: 400;
  color: #3d3d3d;
}

.text-red {
  font-size: 24px;
  font-weight: 400;
  color: rgba(247, 56, 56, 0.75);
}

.input-bg {
  background: #F5F5F5;
  border-radius: 10px;
  width: 100%;
  height: 88px;
}

.input-bg-mul {
  background: #F5F5F5;
  border-radius: 10px;
  width: 100%;
  min-height: 60px;
}


.input {
  background: rgba(0, 0, 0, 0);
  font-size: 28px;
  font-weight: 500;
  color: #333333;
  padding: 0 25px;
}

.input_mul {
  background: rgba(0, 0, 0, 0);
  font-size: 28px;
  font-weight: 800;
  color: #333333;
  padding: 0px;
  margin: 20px 25px;
}

.input_content_conmon {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.create-tab-content {
  z-index: 20;
  position: absolute;
  width: 120px;
  margin-right: 32px;
  margin-top: 6px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid rgba(136, 136, 136, 0.1);
}

.tab-item {
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.tab-color {
  font-size: 24px;
  font-weight: 400;
  color: rgba(61, 61, 61, 0.59);
  line-height: 17px;
}
</style>
